import axios from "axios";
import {creds} from "@/states/Cred";

export default class AutoReplyService {
    getAutoReply(accountName, packageName) {
        return axios.get(`${process.env.VUE_APP_API_URL}/${accountName}/${packageName}/autoreply`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    updateAutoReply(accountName, packageName, autoreview) {
        return axios.put(`${process.env.VUE_APP_API_URL}/${accountName}/${packageName}/autoreply`,
            autoreview,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }
}
