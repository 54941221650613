<template>
    <Toast/>
    <div class="card">
        <DataView :value="criteria" layout="grid" :paginator="true" :rows="9">
            <template #header>
                <Toolbar class="mb-4">
                    <template #start>
                        <h5 class="mb-2 md:m-0 p-as-md-center">{{ lastSearchCriteria.account.accountName }} -
                            {{ lastSearchCriteria.app.appName }} Auto Review Criteria</h5>
                    </template>

                    <template #end>
                        <div class="field">
                            <label for="scheduleInSeconds">Control New Reviews in Every Seconds:</label>
                            <InputText id="scheduleInSeconds" type="number" style="width: 70px"
                                       v-model="autoReply.scheduleInSeconds"
                                       @change="updateAutoReply()"/>
                        </div>

                        <div class="field mr-4">
                            <ToggleButton v-model="autoReply.enabled" @change="updateAutoReply()" onLabel="Enabled"
                                          offLabel="Disabled"
                                          onIcon="pi pi-check" offIcon="pi pi-times" class="w-full sm:w-10rem"/>
                        </div>
                        <div class="field mr-3">
                            <Button label="New" icon="pi pi-plus" class="p-button-success mr-2"
                                    @click="openNewCriteriaDialog"/>
                            <Button label="Copy" icon="pi pi-plus" class="p-button-success mr-2"
                                    @click="openCopyCriteriaDialog"/>
                        </div>
                    </template>
                </Toolbar>
            </template>

            <template #grid="slotProps">
                <div class="col-12 md:col-4">
                    <Card class="criteria-card">
                        <template #title>
                            <div class="criteria-card-title">
                                {{ slotProps.data.name }}
                            </div>
                        </template>
                        <template #content>
                            <div class="criteria-card-content">
                                <div class="field">
                                    Review Contains: {{ slotProps.data.criteria.contains }}
                                </div>

                                <div class="field">
                                    Review Not Contains: {{ slotProps.data.criteria.notContains }}
                                </div>

                                <div class="field">
                                    Review Rating: {{ slotProps.data.criteria.rating }}
                                </div>

                                <div class="field">
                                    Review Length: {{ slotProps.data.criteria.length }}
                                </div>

                                <div class="field">
                                    <label>Status: {{ slotProps.data.enabled ? 'Enabled' : 'Disabled' }}</label>
                                </div>

                                <div>
                                    Reply with template:
                                    {{ slotProps.data.templateName }}
                                </div>
                            </div>
                        </template>
                        <template #footer>
                            <Toolbar class="criteria-card-toolbar">
                                <template #end>
                                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                            @click="editCriteria(slotProps.data)"/>
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                            @click="openDeleteCriteriaDialog(slotProps.data)"/>
                                </template>
                            </Toolbar>
                        </template>
                    </Card>
                </div>
            </template>
        </DataView>

        <Dialog v-model:visible="newCriteriaDialog" :style="{width: '450px'}" header="New Criteria" :modal="true"
                class="p-fluid">
            <div class="field">
                <label for="name">Name</label>
                <InputText id="name" v-model.trim="selectedCriteria.name" required="true" autofocus
                           :class="{'p-invalid': !selectedCriteria.name}"/>
                <small class="p-error" v-if="!selectedCriteria.name">Name is required.</small>
            </div>

            <div class="field">
                <label for="contains" class="mb-3">Review Contains</label>
                <Chips id="contains" v-model="selectedCriteria.criteria.contains" separator=","/>
            </div>

            <div class="field">
                <label for="notContains" class="mb-3">Review Not Contains</label>
                <Chips id="notContains" v-model="selectedCriteria.criteria.notContains" separator=","/>
            </div>

            <div class="field">
                <label for="rating" class="mb-3">Review Rating: {{ selectedCriteria.criteria.rating }}</label>
                <Slider id="rating" v-model="selectedCriteria.criteria.rating" :step="1" :range="true" :min="0"
                        :max="5"/>
            </div>

            <div class="field">
                <label for="length" class="mb-3">Review Length: {{ selectedCriteria.criteria.length }} </label>
                <Slider id="length" v-model="selectedCriteria.criteria.length" :step="1" :range="true" :min="0"
                        :max="500"/>
            </div>

            <div class="field">
                <label for="criteriaEnabled">Enabled: </label>
                <InputSwitch style="top: 7px;" id="criteriaEnabled" v-model="selectedCriteria.enabled"/>
            </div>

            <div class="field">
                <label for="criteriaTemplate" class="mb-3">Template</label>
                <Dropdown id="criteriaTemplate" v-model="selectedCriteria.templateName" :options="templates"
                          optionLabel="templateName"
                          option-value="templateName"
                          placeholder="Select a Template">
                    <template #value="slotProps">
                        <div v-if="slotProps.templateName">
                            {{ slotProps.templateName }}
                        </div>
                    </template>
                </Dropdown>
            </div>

            <div class="field">
                <label for="templateContents" class="mb-3">Template Contents</label>
                <Textarea id="templateContents" v-model="templatePreview" disabled rows="5" cols="30"/>
            </div>

            <template #footer>
                <Button label="Save" icon="pi pi-check" class="p-button-text" @click="createCriteria"/>
                <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeCreateNewCriteriaDialog"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="copyCriteriaDialog" :style="{width: '450px'}" header="Copy Criteria" :modal="true"
                class="p-fluid">
            <div class="grid p-fluid">
                <div class="col-12 md:col-4">
                    <div class="field">
                        <Dropdown v-model="selectedAccount" :options="accounts" disabled
                                  optionLabel="accountName" placeholder="Select Account"/>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 md:col-4">
                    <div class="field">
                        <Dropdown v-model="selectedApp" :options="apps"
                                  optionLabel="appName" placeholder="Select App"/>
                    </div>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 md:col-4">
                    <div class="field">
                        <MultiSelect v-model="selectedCopyCriterias" :options="copyCriterias" optionLabel="name"
                                     placeholder="Select Criterias"
                                     :maxSelectedLabels="3" class="w-full md:w-20rem"/>
                    </div>
                </div>
            </div>

            <template #footer>
                <Button label="Copy" icon="pi pi-check" class="p-button-text" @click="copyCriteria"/>
                <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeCopyCriteriaDialog"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="editCriteriaDialog" :style="{width: '450px'}" header="Edit Criteria" :modal="true"
                class="p-fluid">
            <div class="field">
                <label for="name">Name</label>
                <InputText id="name" v-model.trim="selectedCriteria.name" required="true" autofocus
                           :class="{'p-invalid': !selectedCriteria.name}"/>
                <small class="p-error" v-if="!selectedCriteria.name">Name is required.</small>
            </div>
            <div class="field">
                <label for="contains" class="mb-3">Review Contains</label>
                <Chips id="contains" v-model="selectedCriteria.criteria.contains" separator=","/>
            </div>

            <div class="field">
                <label for="notContains" class="mb-3">Review Not Contains</label>
                <Chips id="notContains" v-model="selectedCriteria.criteria.notContains" separator=","/>
            </div>

            <div class="field">
                <label for="rating" class="mb-3">Review Rating: {{ selectedCriteria.criteria.rating }}</label>
                <Slider id="rating" v-model="selectedCriteria.criteria.rating" :step="1" :range="true" :min="0"
                        :max="5"/>
            </div>

            <div class="field">
                <label for="length" class="mb-3">Review Length: {{ selectedCriteria.criteria.length }} </label>
                <Slider id="length" v-model="selectedCriteria.criteria.length" :step="1" :range="true" :min="0"
                        :max="500"/>
            </div>

            <div class="field">
                <label for="criteriaEnabled">Enabled: </label>
                <InputSwitch style="top: 7px;" id="criteriaEnabled" v-model="selectedCriteria.enabled"/>
            </div>

            <div class="field">
                <label for="criteriaTemplate" class="mb-3">Template</label>
                <Dropdown id="criteriaTemplate" v-model="selectedCriteria.templateName" :options="templates"
                          optionLabel="templateName"
                          option-value="templateName"
                          placeholder="Select a Template">
                    <template #value="slotProps">
                        <div v-if="slotProps.templateName">
                            {{ slotProps.templateName }}
                        </div>
                    </template>
                </Dropdown>
            </div>

            <div class="field">
                <label for="templateContents" class="mb-3">Template Contents</label>
                <Textarea id="templateContents" v-model="templatePreview" disabled rows="5" cols="30"/>
            </div>

            <template #footer>
                <Button label="Save" icon="pi pi-check" class="p-button-text" @click="updateCriteria"/>
                <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeEditCriteriaDialog"/>
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteCriteriaDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                <span v-if="selectedCriteria">Are you sure you want to delete criteria named:  <b>{{
                    selectedCriteria.name
                    }}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="closeDeleteCriteriaDialog()"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteCriteria()"/>
            </template>
        </Dialog>
    </div>
</template>
<script>

import CriteriaService from "@/service/CriteriaService";
import {lastSearchCriteria} from "@/states/State";
import TemplateService from "@/service/TemplateService";
import AutoReplyService from "@/service/AutoReplyService";
import * as loadash from 'lodash';
import AccountService from "@/service/AccountService";
import PlaystoreAppService from "@/service/PlaystoreAppsService";

export default {
    name: "Criteria",
    templateService: null,
    autoReplyService: null,

    computed: {
        lastSearchCriteria() {
            return lastSearchCriteria
        }
    },


    data() {
        return {
            criteriaService: null,
            autoReplyService: null,
            accountService: null,
            selectedCriteria: null,
            accounts: [],
            apps: [],
            templates: [],
            criteria: [],
            editCriteriaDialog: false,
            deleteCriteriaDialog: false,
            newCriteriaDialog: false,
            copyCriteriaDialog: false,
            selectedTemplate: null,
            templatePreview: null,
            selectedAccount: null,
            selectedApp: null,
            copyCriterias: [],
            selectedCopyCriterias: null,
            autoReply: {
                enabled: false
            }
        }
    },

    created() {
        this.criteriaService = new CriteriaService()
        this.templateService = new TemplateService()
        this.autoReplyService = new AutoReplyService()
        this.accountService = new AccountService()
        this.appService = new PlaystoreAppService()
    },

    mounted() {
        this.templateService.getTemplatesByAccount(lastSearchCriteria.account._id)
            .then(res => {
                if (res.status !== 200) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error Occurred while Fetching templates',
                        detail: res.status + " " + res.data.detail,
                        life: 3000
                    });
                } else {
                    this.templates = res.data
                }
            })

        this.accountService.getAccounts()
            .then(accounts => {
                this.accounts = accounts
                return accounts
            })
            .then(accounts => accounts.forEach(account => {
                if (account.accountName === lastSearchCriteria.account.accountName) {
                    this.selectedAccount = account
                }
            }));

        this.autoReplyService.getAutoReply(lastSearchCriteria.account.accountName, lastSearchCriteria.app.packageName)
            .then(res => {
                if (res.status !== 200) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Fetch Auto Reply Failed',
                        detail: res.status + " " + res.data.detail,
                        life: 3000
                    });
                } else {
                    this.autoReply = res.data
                }
            })

        this.refreshCriteria()

    },

    watch: {
        'selectedCriteria.templateName': function (val) {
            if (val == null) {
                this.templatePreview = null
            } else {
                for (let i = 0; i < this.templates.length; i++) {
                    if (this.templates[i].templateName === val) {
                        this.templatePreview = this.templates[i].templateContent
                        break;
                    }
                }
            }
        },
        'selectedAccount': function () {
            this.appService.getApps(this.selectedAccount._id).then(res => this.apps = res.data)
        },
        'selectedApp': function () {
            this.criteriaService.getCriteria(this.selectedAccount.accountName, this.selectedApp.packageName).then(res => this.copyCriterias = res.data)
        }
    },

    methods: {

        refreshCriteria() {
            return this.criteriaService.getCriteria(lastSearchCriteria.account.accountName, lastSearchCriteria.app.packageName)
                .then(res => {
                    if (res.status !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Criteria Create Failed',
                            detail: res.status + " " + res.data.detail,
                            life: 3000
                        });
                    } else {
                        this.criteria = res.data
                    }
                })
        },

        updateAutoReply() {
            this.autoReplyService.updateAutoReply(lastSearchCriteria.account.accountName, lastSearchCriteria.app.packageName, this.autoReply)
                .then(res => {
                    if (res.status !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Update Failed',
                            detail: res.status + " " + res.data.detail,
                            life: 3000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Successfully updated',
                            life: 3000
                        });
                    }
                })
        },

        editCriteria(criteria) {
            this.selectedCriteria = loadash.cloneDeep(criteria)
            this.editCriteriaDialog = true
        },

        openDeleteCriteriaDialog(criteria) {
            this.selectedCriteria = criteria
            this.deleteCriteriaDialog = true
        },

        openNewCriteriaDialog() {
            this.selectedCriteria = {
                criteria: {
                    contains: null,
                    length: [0, 500],
                    rating: [0, 5],
                }
            }
            this.newCriteriaDialog = true
        },

        openCopyCriteriaDialog() {
            this.copyCriteriaDialog = true
        },

        deleteCriteria() {
            this.criteriaService.delete(this.selectedCriteria)
                .then(res => {
                    if (res.status !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Criteria Delete Failed',
                            detail: res.status + " " + res.data.detail,
                            life: 3000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Criteria Deleted',
                            life: 3000
                        });
                    }
                }).then(() => this.refreshCriteria())
                .then(() => this.closeDeleteCriteriaDialog())
        },

        updateCriteria() {
            this.criteriaService.save(lastSearchCriteria.account.accountName,
                lastSearchCriteria.app.packageName,
                this.selectedCriteria)
                .then(res => {
                    if (res.status !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Criteria Update Failed',
                            detail: res.status + " " + res.data.detail,
                            life: 3000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Criteria Updated',
                            life: 3000
                        });
                    }
                })
                .then(() => this.refreshCriteria())
                .then(() => this.closeEditCriteriaDialog())
        },

        copyCriteria() {
            Promise.resolve(
                this.selectedCopyCriterias.map(copyCriteria =>
                    this.criteriaService.createCriteria(lastSearchCriteria.account.accountName, lastSearchCriteria.app.packageName, {
                        'criteria': copyCriteria.criteria,
                        'name': copyCriteria.name,
                        'templateName': copyCriteria.templateName,
                        'enabled': copyCriteria.enabled,
                    })
                ))
                .then(() => this.refreshCriteria())
                .then(() => this.closeCopyCriteriaDialog())
        },

        createCriteria() {

            this.criteriaService.createCriteria(lastSearchCriteria.account.accountName,
                lastSearchCriteria.app.packageName,
                this.selectedCriteria)
                .then(res => {
                    if (res.status !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Criteria Create Failed',
                            detail: res.status + " " + res.data.detail,
                            life: 3000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Criteria Created',
                            life: 3000
                        });


                    }
                })
                .then(() => this.refreshCriteria())
                .then(() => this.closeCreateNewCriteriaDialog())
        },

        updateAutoReplyStatus(sel) {
            this.autoReply.enabled = sel.value === 'On';
        },

        closeDeleteCriteriaDialog() {
            this.selectedCriteria = null
            this.deleteCriteriaDialog = false
        },

        closeEditCriteriaDialog() {
            this.selectedCriteria = null
            this.editCriteriaDialog = false
        },

        closeCreateNewCriteriaDialog() {
            this.selectedCriteria = null
            this.newCriteriaDialog = false
        },

        closeCopyCriteriaDialog() {
            this.copyCriteriaDialog = false
        }
    }
}

</script>

<style lang="scss" scoped>

.criteria-card {
  border: 1px solid var(--surface-border);
  border-radius: 3px;
  margin: .3rem;
}

.criteria-if {

}

.criteria-template {
}

.criteria-card-toolbar {
  border: 0
}

.p-slider-horizontal {
  width: 14rem;
}

.p-slider-vertical {
  height: 14rem;
}

.scheduleInSeconds {
  width: 100px;
}

</style>
