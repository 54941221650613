import axios from "axios";
import {creds} from "@/states/Cred";

export default class CriteriaService {
    createCriteria(accountName, packageName, criteria) {
        return axios.post(`${process.env.VUE_APP_API_URL}/${accountName}/${packageName}/criteria`,
            criteria,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    getCriteria(accountName, packageName, criteriaName) {
        return axios.get(`${process.env.VUE_APP_API_URL}/${accountName}/${packageName}/criteria`,
            {headers: {"Authorization": `Bearer ${creds.token}`}, params: {'criteriaName': criteriaName}})
            .then(res => res)
            .catch(err => err.response)
    }

    save(accountName, packageName, criteria) {
        return axios.put(`${process.env.VUE_APP_API_URL}/${accountName}/${packageName}/criteria`,
            criteria,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }

    delete(criteria) {
        return axios.delete(`${process.env.VUE_APP_API_URL}/criteria/${criteria._id}`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }
}
